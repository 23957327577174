/* ==========================================================================
  main.js
========================================================================== */

/**
 * Init dropdown-menus
 */
var dropdown_menus = document.querySelectorAll('.js-navigation');
for (var i = 0; i < dropdown_menus.length; i++) {
  component_dropdown_menu({
    desktop_min_width: 890,
    menu: dropdown_menus[i]
  });
}

/**
 * Init mobile menu
 */
component_mobile_menu({
  menu:     document.querySelector('.js-mobile-menu'),
  site:     document.querySelector('.js-page'),
  toggles:  document.querySelectorAll('.js-menu-toggle')
});

/**
 * Init Responsive videos
 */
fitvids();

/**
 * Init polyfill object-fit for lazyloaded
 */
if (typeof objectFitPolyfill === "function") {
  document.addEventListener('lazybeforeunveil', function(e){
    objectFitPolyfill();
    e.target.addEventListener('load', function() {
      objectFitPolyfill();
    });
  });
}

/**
 * JQuery part.
 */
(function($){

  /* Refresh page if user don't want cookies */
  $('#cn-refuse-cookie').on('click', function(e) {
    window.location.reload();
  });

  if( $('.home-slider').get(0) ) {

    $('.home-slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      autoplay: true,
      autoplaySpeed: 4000,
    });

  }

  /**
   * Init Realisation gallery if exist
   */
  if( $('.realisations-gallery').get(0) ) {

    // store anything commonly called in variables to speed up your code
    const $active = $('.realisations-gallery__menu li.active a').attr('class');
    const $ourHolder = $('.realisations-gallery__holder');

    // hide all elements that aren't in the $active class
    $ourHolder.children('div:not(#realisations-' + $active + ')').hide();

    $('.realisations-gallery__menu li a').on('click', function(e) {

      e.preventDefault();
      // store anything commonly called in variables to speed up your code
      const $this = $(this);
      const ourClass = $this.attr('class');

      // reset the active class on all the buttons
      $('.realisations-gallery__menu li').removeClass('active');

      // update the active state on our clicked button
      $this.parent().addClass('active');

      // hide all elements that don't share ourClass
      $ourHolder.children('div:not(#realisations-' + ourClass + ')').hide();
      // show all elements that do share ourClass
      $ourHolder.children('div#realisations-' + ourClass).fadeIn();

      return false;

    });

    $('.realisations-gallery__content').each(function() { // the containers for all your galleries
        $(this).magnificPopup({
            delegate: 'a', // the selector for gallery item
            type: 'image',
            // Delay in milliseconds before popup is removed
            removalDelay: 300,

            // Class that is added to popup wrapper and background
            // make it unique to apply your CSS animations just to this exact popup
            mainClass: 'mfp-fade',
            gallery: {
                enabled: true,
                tPrev: 'Précédent (touche flèche de gauche)', // title for left button
                tNext: 'Suivant (touche flèche de droite)', // title for right button
                tCounter: '<span class="mfp-counter">%curr% sur %total%</span>' // markup of counter
            }
        });
    });

  }

  // Bloc : Galerie avec Lightbox
  if( $('.wp-block-gallery').get(0) ) {

    $('.wp-block-gallery').each(function() { // the containers for all your galleries
        $(this).magnificPopup({
            delegate: 'a', // the selector for gallery item
            type: 'image',
            // Delay in milliseconds before popup is removed
            removalDelay: 300,

            // Class that is added to popup wrapper and background
            // make it unique to apply your CSS animations just to this exact popup
            mainClass: 'mfp-fade',
            gallery: {
                enabled: true,
                tPrev: 'Précédent (touche flèche de gauche)', // title for left button
                tNext: 'Suivant (touche flèche de droite)', // title for right button
                tCounter: '<span class="mfp-counter">%curr% sur %total%</span>' // markup of counter
            }
        });
    });

  }

  if( $('.page-slider').get(0) ) {

    $('.page-slider').each(function() { // the containers for all your galleries
        $(this).slick({
          arrows        : false,
          dots          : false,
          infinite      : true,
          speed         : 500,
          fade          : true,
          cssEase       : 'linear',
          slidesToShow  : 1,
        });
    });

  }

})(jQuery);

/* Smooth scroll */

const links = document.querySelectorAll(".button--smooth");

function clickHandler(e) {
  e.preventDefault();
  const href = this.getAttribute("href");
  const offsetTop = document.querySelector(href).offsetTop;

  scroll({
    top: offsetTop,
    behavior: "smooth"
  });
}

for (const link of links) {
  link.addEventListener("click", clickHandler);
}
